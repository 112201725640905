<template>
  <el-form :model="deviceForm" label-width="30%" :rules="insRules" ref="deviceForm" :hide-required-asterisk="true" size="small">
    <el-form-item label="本批设备所属单位：" prop="school">
      <el-select v-model="deviceForm.school" placeholder="请选择本批设备所属单位" @change="schoolChange">
        <el-option v-for="item in unitList" :key="item.id" :label="item.label" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="type" label="本批设备型号：">
      <el-input v-model="deviceForm.type" placeholder="请输入本批设备型号">
      </el-input>
    </el-form-item>
    <el-form-item prop="content" label="本批设备备注：">
      <el-input v-model="deviceForm.content" placeholder="请输入本批设备备注">
      </el-input>
    </el-form-item>
    <el-form-item label="所属供应商：" prop="supplierId">
      <el-select v-model="deviceForm.supplierId" placeholder="请选择所属供应商">
        <el-option v-for="item in supplierList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <!-- <el-form-item prop="supplierId" label="滤芯维护服务商：">
      <el-input v-model="deviceForm.supplierId" placeholder="请输入滤芯维护服务商">
      </el-input>
    </el-form-item> -->
    <el-row>
      <el-col :span="12">
        <el-form-item prop="preAlertThreshold" label="流量预报警值：">
          <el-input type="number" v-model="deviceForm.preAlertThreshold" placeholder="请输入流量预报警值">
            <template slot="append">t</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="alertThreshold" label="流量报警值：">
          <el-input type="number" v-model="deviceForm.alertThreshold" placeholder="请输入流量报警值">
            <template slot="append">t</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row class="single" v-for="(item,index) in deviceForm.option" :key="index">
      <el-col :span="8">
        <el-form-item :prop="`option.${index}.code`" label="设备编号：" :rules="{
                required: true,
                message: '请输入设备编号',
                trigger: 'blur',
            }">
          <el-input v-model="item.code" placeholder="请输入设备编号">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="安装地址：" :prop="`option.${index}.location`" :rules="{
                required: true,
                message: '请输入安装地址',
                trigger: 'blur',
            }">
          <el-select v-model="item.location" placeholder="请选择安装地址">
            <el-option v-for="item in pointList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item :prop="`option.${index}.number`" label="初始流量：" :rules="{
                required: true,
                message: '请输入初始流量',
                trigger: 'blur',
            }">
          <el-input type="number" v-model="item.number" placeholder="请输入初始流量">
            <template slot="append">t</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-button type="text" v-if="index !== 0" @click="deleteInput(index)">删除</el-button>
    </el-row>
    <div class="add">
      <el-button type="success" size="small" @click="addInput">新 增</el-button>
    </div>
    <el-divider></el-divider>
    <div class="item" v-if="infoMsg">
      <p>
        报警通知：
        <span v-if="infoMsg.alertInfo&&infoMsg.alertInfo.length">
          <span>{{infoMsg.alertInfo[0].name}}</span>
          <span>{{infoMsg.alertInfo[0].phone}}</span>
          <span>{{infoMsg.alertInfo[0].organization}}</span>
          <span>{{infoMsg.alertInfo[0].email}}</span>
        </span>
      </p>
      <p>
        维修通知：
        <span v-if="infoMsg.repairInfo&&infoMsg.repairInfo.length">
          <span>{{infoMsg.repairInfo[0].name}}</span>
          <span>{{infoMsg.repairInfo[0].phone}}</span>
          <span>{{infoMsg.repairInfo[0].organization}}</span>
          <span>{{infoMsg.repairInfo[0].email}}</span>
        </span>
      </p>
    </div>
    <el-form-item style="text-align:right">
      <el-button type="primary" size="small" @click="submit('deviceForm')" :loading="submitLoading">提交</el-button>
      <el-button size="small">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getPointList, getSupplierList, getDeviceInfo, addDevice } from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      deviceForm: {
        type: '',
        content: '',
        supplierId: '',
        school: '',
        alertThreshold: '',
        preAlertThreshold: '',
        option: [
          {
            code: '',
            location: '',
            number: 0
          }
        ]
      },
      insRules: {
        school: [
          { required: true, message: '请选择本批设备所属单位', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请输入本批设备型号', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入本批设备备注', trigger: 'blur' }
        ],
        supplierId: [
          { required: true, message: '请选择所属供应商', trigger: 'change' }
        ],
        preAlertThreshold: [
          { required: true, message: '请输入流量预报警值', trigger: 'blur' }
        ],
        alertThreshold: [
          { required: true, message: '请输入流量报警值', trigger: 'blur' }
        ]
      },
      // addForm: {
      //   // 电量报警阈值
      //   batteryThreshold: 10,
      // 批件id
      approvalId: null,
      // },
      pointList: [],
      supplierList: [],
      infoMsg: {},
      submitLoading: false
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['unitList', 'realname'])
  },
  methods: {
    // 获取供应商列表
    async init (approvalId) {
      this.supplierList = await getSupplierList()
      this.approvalId = approvalId
    },
    // 添加设备
    submit () {
      this.$refs.deviceForm.validate((valid) => {
        if (valid) {
          // console.log('valid :>> ', valid);
          // console.log('this.deviceForm :>> ', this.deviceForm);
          const { school, type, content, supplierId, preAlertThreshold, alertThreshold, option } = this.deviceForm
          const supplierName = this.supplierList.filter(el => el.id === supplierId)[0].name
          const params = option.map(el => {
            return {
              // 学校id
              school,
              // 设备型号
              type,
              // 设备备注
              content,
              // 供应商id，名称
              supplierId,
              supplierName,
              // 原有字段都传供应商名称
              filterprovider: supplierName,
              filterMaintainer: supplierName,
              // 流量预报警阈值
              preAlertThreshold: Number(preAlertThreshold),
              // 流量报警阈值
              alertThreshold: Number(alertThreshold),
              // 电量报警阈值 写死
              batteryThreshold: 10,
              // 设备编号
              deviceId: el.code,
              // 安装地址
              location: this.pointList.filter(item => item.id === el.location)[0].name,
              regionId: el.location,
              // 初始流量
              initFlow: Number(el.number),
              approvalId: this.approvalId
            }
          })
          const codes = option.map(el => {
            return el.code
          })
          this.submitLoading = true
          addDevice({ deviceAddDTO: JSON.stringify(params) }).then(() => {
            this.$message.success('添加设备成功')
            this.$router.push('/device')
            this.$add(
              {
                userName: this.realname,
                module: '设备管理',
                content: `${this.realname}新增设备,设备编号为：${codes.join(',')}`,
                type: 3,
                platform: 8,
                operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
              }
            )
            this.submitLoading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 增加输入框
    addInput () {
      this.deviceForm.option.push(
        {
          code: '',
          location: '',
          number: 0
        })
    },
    deleteInput (index) {
      this.deviceForm.option.splice(index)
    },
    // 选择学校获取点位
    async schoolChange (val) {
      this.pointList = await getPointList({ schoolId: val })
      this.infoMsg = await getDeviceInfo({ schoolId: val })
    }
  }
};
</script>

<style scoped lang="scss">
.el-form {
  width: 70%;
  margin: 30px auto 0;
  .el-form-item {
    width: 80%;
    .el-select {
      width: 100%;
    }
  }
  > .add {
    display: flex;
    justify-content: flex-end;
    // .el-button {
    //   width: 10%;
    // }
  }
  .single {
    position: relative;
    > .el-button {
      position: absolute;
      right: 0;
    }
  }
  .item {
    margin-bottom: 10px;
    p {
      font-size: 16px;
      color: #606266;
      margin-bottom: 14px;
      span {
        span {
          margin-right: 10px;
        }
      }
    }
    p:last-child {
      margin: 0;
    }
    padding: 10px;
    border: 1px solid #dcdfe6;
    // margin-bottom: 10px;
  }
}
</style>
