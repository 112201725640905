<template>
  <div class='addDevice'>
    <el-steps :active="active" finish-status="success" space="50%" align-center>
      <el-step title="上传批件" icon="el-icon-upload"></el-step>
      <el-step title="新增设备" icon="el-icon-edit"></el-step>
    </el-steps>
    <el-form :model="insForm" label-width="30%" :rules="insRules" ref="insForm" :hide-required-asterisk="true" v-if="active === 0">
      <el-form-item prop="testorg" label="批件发放单位：">
        <el-input v-model="insForm.testorg" placeholder="请输入批件发放单位">
        </el-input>
      </el-form-item>
      <el-form-item prop="testmodel" label="批件检测的设备型号：">
        <el-input v-model="insForm.testmodel" placeholder="请输入批件检测的设备型号">
        </el-input>
      </el-form-item>
      <el-form-item prop="approvaltotalflow" label="批件的内设备的额定流量大小：">
        <el-input v-model="insForm.approvaltotalflow" placeholder="请输入批件的内设备的额定流量大小">
        </el-input>
      </el-form-item>
      <el-form-item prop="approvaldate" label="批件审批的日期：">
        <el-date-picker type="date" placeholder="请输入批件审批的日期：" v-model="insForm.approvaldate" style="width: 100%;"></el-date-picker>
      </el-form-item>
      <el-form-item prop="approvalstaledate" label="选择批件有效日期：">
        <el-date-picker type="date" placeholder="请选择批件有效日期" v-model="insForm.approvalstaledate" style="width: 100%;"></el-date-picker>
      </el-form-item>
      <el-form-item label="本批设备批件：" prop="img">
        <base-upload accept="image/jpeg,image/jpeg,image/png" listType="picture" :file.sync="insForm.img" :isShowTip="false" uploadText="上传批件" @upload="upload" @remove='remove' />
      </el-form-item>
      <el-form-item style="text-align:right">
        <el-button type="primary" @click="next" size="small" :loading="nextLoading">下一步</el-button>
      </el-form-item>
    </el-form>
    <append-device ref="append" v-if="active === 1" />
  </div>
</template>

<script>
import { getDeviceImg, addApproval } from '@/api'
import { mapGetters } from 'vuex'
import AppendDevice from './components/appendDevice.vue'
export default {
  components: { AppendDevice },
  data () {
    return {
      active: 0,
      insForm: {
        img: null,
        approvalid: [],
        testorg: '',
        testmodel: '',
        approvaltotalflow: '',
        approvaldate: '',
        approvalstaledate: ''
      },
      insRules: {
        testorg: [
          { required: true, message: '请输入批件发放单位', trigger: 'blur' }
        ],
        testmodel: [
          { required: true, message: '请输入批件检测的设备型号', trigger: 'blur' }
        ],
        approvalid: [
          { required: true, message: '请上传本批设备批件', trigger: 'change' }
        ],
        img: [
          { required: true, message: '请上传本批设备批件', trigger: 'change' }
        ],
        approvaltotalflow: [
          { required: true, message: '请输入批件的内设备的额定流量大小', trigger: 'blur' }
        ],
        approvaldate: [
          { required: true, message: '请选择批件审批的日期', trigger: 'change' }
        ],
        approvalstaledate: [
          { required: true, message: '请选择批件有效日期', trigger: 'change' }
        ],
      },
      imgList: [],
      nextLoading: false
    };
  },
  created () {

  },
  mounted () {

  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    // 上传图片存
    upload (data) {
      this.imgList.push(data)
    },
    // 删除图片
    remove (data) {
      this.imgList = this.imgList.filter(el => el.file.name !== data.name)
    },
    // 获取图片id
    checkImgId () {
      const img = Promise.all(this.imgList.map(async (el) => {
        let formdata = new FormData()
        formdata.append('file', el.file, el.file.name)
        // this.updataParams.imgId = await getImgId(this.token, formdata)
        const res = await getDeviceImg(this.token, formdata)
        console.log('res[0] :>> ', res[0]);
        return res[0]
      }))
      return img
    },

    async next () {
      const valid = await this.$refs.insForm.validate().catch(err => err)
      // if (this.active++ > 2) this.active = 0;
      // this.$refs.insForm.validate(async (valid) => {
      // console.log('this.imgList :>> ', this.imgList);
      if (valid) {
        // console.log('valid :>> ', valid);
        const img = await this.checkImgId()
        this.nextLoading = true
        const { testorg, testmodel, approvaltotalflow, approvaldate, approvalstaledate } = this.insForm
        const params = {
          approvalid: img.join(','),
          testorg,
          testmodel,
          approvaltotalflow,
          approvaldate: this.$moment(approvaldate).format('YYYY-MM-DD'),
          approvalstaledate: this.$moment(approvalstaledate).format('YYYY-MM-DD')
        }
        // console.log('params :>> ', img);
        const res = await addApproval(params)
        this.$message.success('上传批件成功')
        this.nextLoading = false
        this.active = 1
        this.$refs.append.init(res)
      } else {
        console.log('error submit!!');
        return false;
      }
      // })
    }
  }
};
</script>

<style scoped lang="scss">
.addDevice {
  .el-form {
    width: 70%;
    margin: 30px auto 0;
    .el-form-item {
      width: 80%;
    }
  }
  // .el-button {
  //   text-align: right;
  // }
}
</style>
